.cookieaccept {
}
.header_title
{
    text-align:center;

}
#rcc-decline-button{
    margin-right:15px;

}
.modal-main {
    padding-top: 25px;
    padding-bottom: 25px;
}
.btn-wrapper{
    text-align:center;
}
.CookieConsent {
    max-width: 500px;
    border-radius: 5px;
    padding: 20px 30px;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate3d(-50%, -50%, 9999990px);
    width: 95%;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

.overlayclass {
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
