// Colors
$primary-color : #8CC63F;
$purple-color : #662D91;

// Fonts
$fontMain: 'Montserrat', sans-serif;


// Breakpoints
$breakout1200 : 1200px;
$breakout1024 : 1024px;
$breakout991 : 991px;
$breakout767 : 767px;
$breakout576 : 576px;