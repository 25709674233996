.theme-btn {
  background: $purple-color;
  font-family: $fontMain;
  font-size: 14px;
  line-height: 1.3;
  color: #FFFFFF;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0;
  box-shadow: none;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 0px 12px;
  min-height: 32px;
  margin: 0px;
  font-weight: 500;
  transition: 0.5s ease;

  &:hover {
    opacity: 0.9;
    color: #fff;
  }

}