h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 35px;
    line-height: 42px;
    color: #000;
    margin-bottom: 0;
    font-weight: 700;

    span {
        color: $primary-color;
    }
}

p {
    font-size: 16px;
    line-height: 21px;
    color: #000;
    font-weight: 400;
}

a {
    color: #06c;
    text-decoration: none;
    font-size: 14px;
    line-height: 17px;
}

a:hover {
    color: #06c;
    text-decoration: underline;
}